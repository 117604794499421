<template>
  <main-container>
    <page-sizer>
      <v-card>
        <v-card-title>
          Contact Linda
        </v-card-title>
        <v-form v-model="validForm" ref="contactForm">
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  label="First Name"
                  v-model="name.first"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Last Name"
                  v-model="name.last"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-text-field
              label="E-Mail"
              v-model="email"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
            <v-textarea
              label="Comment or Message"
              v-model="message"
              :rules="[rules.required]"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn class="mx-2" @click="downloadPressKit" color="success" small>
              <v-icon small>mdi-download</v-icon>
            </v-btn>
            <div class="text-subtitle-1">Linda's Press Kit</div>

            <v-spacer></v-spacer>
            <v-btn
              @click="sendMessage"
              :dark="validForm"
              :disabled="!validForm"
            >
              Send
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <v-overlay v-if="$store.state.sendingContact">
        <v-progress-circular
          indeterminate
          size="200"
          color="blue darken-4"
          width="10"
        >
          <h1 class="blue--text text--darken-4">Sending</h1>
        </v-progress-circular>
      </v-overlay>

      <v-snackbar centered v-model="snack.visible" :color="snack.color">
        {{ snack.text }}
      </v-snackbar>
    </page-sizer>
  </main-container>
</template>

<script>
import { mapActions } from "vuex";
import MainContainer from "../components/MainContainer.vue";
import PageSizer from "@/components/PageSizer";

export default {
  components: { MainContainer, PageSizer },
  data: () => ({
    name: {
      first: "",
      last: ""
    },
    email: "",
    message: "",
    rules: {
      required: value => !!value || "Required.",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      }
    },
    validForm: false,
    snack: { visible: false, color: "", text: "" }
  }),

  methods: {
    ...mapActions(["sendContact"]),
    sendMessage() {
      this.sendContact({
        name: this.name,
        email: this.email,
        message: this.message
      }).then(response => {
        console.log({ response });
        if (response.status == "ok") {
          this.$refs.contactForm.reset();
        }

        this.snack =
          response.status == "ok"
            ? {
                visible: true,
                color: "green",
                text: "Contact message sent successfully"
              }
            : {
                visible: true,
                color: "red",
                text: "Unable to send contact message"
              };
      });
    },
    downloadPressKit() {
      window.location = "/LDAddison-Press-Kit.zip";
    }
  }
};
</script>
